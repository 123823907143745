import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { toolIcons } from "../../components/project-tool-icons"
import { SEO } from "../../components/seo"

export default function DettiPos() {
  const toolsUsed = ["mongo", "express", "react", "node", "tailwind", "jwt"]
  const tools = toolsUsed.map((tool, index) => (
    <div
      key={`detti-tool-${index}`}
      className="h-20 hover:scale-125 transition-transform ease-in-out duration-200"
    >
      {toolIcons[tool]}
    </div>
  ))
  return (
    <div className=" bg-sv-blue-dark h-full min-h-screen p-12">
      <nav className="mx-auto max-w-2xl text-white mb-8">
        <Link
          to="/#projects"
          className="border-2 border-white rounded-md px-3 py-1 flex justify-center items-center w-fit font-semibold hover:scale-110 hover:bg-sv-blue-medium transition-all ease-in-out duration-200"
        >
          <span className="material-symbols-rounded font-semibold mr-2">
            arrow_back
          </span>
          Home
        </Link>
      </nav>
      <main className="text-white font-montserrat space-y-12 mx-auto max-w-2xl">
        {/* top */}
        <div className="space-y-5">
          {/* title and description */}
          <div>
            <h1 className="text-4xl font-medium mb-3">dettiPOS</h1>
            <p className="text-xl">
              A Point Of Sale System, originally created for a small South
              African bakery, that is designed to be simple and easy to use.
            </p>
          </div>
          <div>
            <a
              className=" bg-sv-orange p-3 font-bold rounded-md text-lg shadow-md flex items-center gap-2 justify-center w-fit hover:scale-105 transition-all ease-in-out duration-100"
              href="https://detti-pos.netlify.app/"
            >
              View Application
              <span className="material-symbols-rounded font-bold text-2xl">
                arrow_forward
              </span>
            </a>
          </div>
          <div>
            <StaticImage
              src="../../images/screenshots/detti.png"
              alt="Detti POS Order Screen"
              className="rounded-lg"
            />
          </div>
        </div>

        {/* Longer description */}
        <div className="space-y-5 text-lg">
          <h2 className="font-medium text-3xl">Description</h2>
          <p>
            This is a full-stack web application built on the{" "}
            <strong className="tracking-wide">MERN stack</strong> and was
            developed as a{" "}
            <strong className="tracking-wide">group project</strong> for the
            Software Engineering Immersive course at General Assembly.
          </p>
          <p>
            This application allows business owners to manage their{" "}
            <strong className="tracking-wide">
              orders, products, and employees
            </strong>{" "}
            and enables employees to process{" "}
            <strong className="tracking-wide">transactions</strong>.
            Administrators can see{" "}
            <strong className="tracking-wide">reports</strong> about their sales
            and have complete control over the{" "}
            <strong className="tracking-wide">permissions</strong> of their
            employees.
          </p>
        </div>

        <div className="flex flex-wrap items-center gap-x-5 gap-y-2">
          <h2 className="font-medium text-lg">Authors:</h2>
          <ul className="flex gap-5 underline underline-offset-2">
            <li>
              <a
                href="https://www.linkedin.com/in/stefan-vosloo"
                target="_blank"
                rel="noreferrer"
                className="block hover:scale-105 hover:text-[#8CCBB4] transition-all ease-in-out duration-100"
              >
                Stefan Vosloo
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/grace-narez-8b0498238/"
                target="_blank"
                rel="noreferrer"
                className="block hover:scale-105 hover:text-[#8CCBB4] transition-all ease-in-out duration-100"
              >
                Grace Narez
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/huseyingumus"
                target="_blank"
                rel="noreferrer"
                className="block hover:scale-105 hover:text-[#8CCBB4] transition-all ease-in-out duration-100"
              >
                Heg Gumus
              </a>
            </li>
          </ul>
        </div>

        {/* Built with */}
        <div className="space-y-5">
          <h2 className="font-medium text-3xl">Built With</h2>
          <div className="flex flex-wrap gap-3 justify-center items-center bg-sv-blue-medium rounded-xl p-10 my-8">
            {tools}
          </div>
          <div className="text-lg ml-5">
            <ul className="list-['🛠'] ml-3">
              <li className="pl-2">
                This project uses a <strong>MERN</strong> stack:
                <ul className="list-['⚙️'] ml-5">
                  <li className="pl-2">
                    an <strong>ExpressJS</strong> backend,
                  </li>
                  <li className="pl-2">
                    <strong>MongoDB</strong> database,
                  </li>
                  <li className="pl-2">
                    <strong>React</strong> frontend,
                  </li>
                  <li className="pl-2">
                    and a <strong>NodeJS</strong> server.
                  </li>
                </ul>
              </li>
              <li className="pl-2">
                For styling we used <strong>TailwindCSS</strong>.
              </li>
              <li className="pl-2">
                Authentication is based on <strong>JWT tokens</strong>.
              </li>
            </ul>
          </div>
        </div>

        {/* Github card */}
        <div className="flex justify-center items-center gap-5 flex-wrap bg-sv-blue-medium rounded-xl px-5 py-10">
          <p className="text-center max-w-sm">
            For all the technical details, check out the README on GitHub.
          </p>
          <a
            className="bg-sv-orange p-3 font-bold rounded-md text-lg shadow-md flex items-center gap-2 justify-center w-fit hover:scale-105 transition-all ease-in-out duration-100"
            href="https://github.com/saulthebear/pos-client#readme"
          >
            GitHub
            <svg viewBox="0 0 128 128" className="h-7 fill-white">
              <g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M64 5.103c-33.347 0-60.388 27.035-60.388 60.388 0 26.682 17.303 49.317 41.297 57.303 3.017.56 4.125-1.31 4.125-2.905 0-1.44-.056-6.197-.082-11.243-16.8 3.653-20.345-7.125-20.345-7.125-2.747-6.98-6.705-8.836-6.705-8.836-5.48-3.748.413-3.67.413-3.67 6.063.425 9.257 6.223 9.257 6.223 5.386 9.23 14.127 6.562 17.573 5.02.542-3.903 2.107-6.568 3.834-8.076-13.413-1.525-27.514-6.704-27.514-29.843 0-6.593 2.36-11.98 6.223-16.21-.628-1.52-2.695-7.662.584-15.98 0 0 5.07-1.623 16.61 6.19C53.7 35 58.867 34.327 64 34.304c5.13.023 10.3.694 15.127 2.033 11.526-7.813 16.59-6.19 16.59-6.19 3.287 8.317 1.22 14.46.593 15.98 3.872 4.23 6.215 9.617 6.215 16.21 0 23.194-14.127 28.3-27.574 29.796 2.167 1.874 4.097 5.55 4.097 11.183 0 8.08-.07 14.583-.07 16.572 0 1.607 1.088 3.49 4.148 2.897 23.98-7.994 41.263-30.622 41.263-57.294C124.388 32.14 97.35 5.104 64 5.104z"
                ></path>
                <path d="M26.484 91.806c-.133.3-.605.39-1.035.185-.44-.196-.685-.605-.543-.906.13-.31.603-.395 1.04-.188.44.197.69.61.537.91zm2.446 2.729c-.287.267-.85.143-1.232-.28-.396-.42-.47-.983-.177-1.254.298-.266.844-.14 1.24.28.394.426.472.984.17 1.255zM31.312 98.012c-.37.258-.976.017-1.35-.52-.37-.538-.37-1.183.01-1.44.373-.258.97-.025 1.35.507.368.545.368 1.19-.01 1.452zm3.261 3.361c-.33.365-1.036.267-1.552-.23-.527-.487-.674-1.18-.343-1.544.336-.366 1.045-.264 1.564.23.527.486.686 1.18.333 1.543zm4.5 1.951c-.147.473-.825.688-1.51.486-.683-.207-1.13-.76-.99-1.238.14-.477.823-.7 1.512-.485.683.206 1.13.756.988 1.237zm4.943.361c.017.498-.563.91-1.28.92-.723.017-1.308-.387-1.315-.877 0-.503.568-.91 1.29-.924.717-.013 1.306.387 1.306.88zm4.598-.782c.086.485-.413.984-1.126 1.117-.7.13-1.35-.172-1.44-.653-.086-.498.422-.997 1.122-1.126.714-.123 1.354.17 1.444.663zm0 0"></path>
              </g>
            </svg>
          </a>
        </div>
      </main>
    </div>
  )
}

export const Head = () => (
  <SEO
    title="dettiPOS | Stefan Vosloo"
    description="A Point Of Sale System, originally created for a small South
              African bakery, that is designed to be a simple and easy to use."
  />
)
